import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select, notification } from 'antd';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import axios from '../../services/axiosInstance';
import { getDetails, userCreate } from '../../services/auth';

const { Option } = Select;

export default function ContractorSupervisorModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
  locationsData,
  roleTypeData,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();
  const [users, setUsers] = React.useState({});
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredLocation, setFilteredLocation] = React.useState(locationsData);

  const orgId = userConfig.orgId;

  useEffect(() => {
    console.log(userConfig, 'config')
    console.log(locationsData, 'loc')
    if (
      userConfig?.role == 'contractor' &&
      // locationsData?.locations &&
      userConfig?.location
    ) {
      const newObj = {
        locations: locationsData?.locations?.filter(
          (l) => l.id == userConfig?.location
        ),
      };
      setFilteredLocation(newObj);
    } else {
      setFilteredLocation({ locations: locationsData?.locations?.slice(1) });
    }
  }, [locationsData]);

  const validateContractorStatus = (rule, value, callback) => {
    const selectedOption = users.Response1.find(
      (option) => option.id === value
    );

    if (selectedOption) {
      const contractorStatus = selectedOption.status;

      if (contractorStatus) {
        const statusLowerCase = contractorStatus.toLowerCase();

        if (statusLowerCase === 'active' || statusLowerCase === 'rehired') {
          callback(); // Validation passed
        } else {
          callback(`Cannot proceed with status: ${contractorStatus}`);
        }
      } else {
        callback('Invalid contractor selected'); // Handle the case where the selected option is not found
      }
    }
  };

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const currentValues = formRef.current?.getFieldsValue();

  // Get current form values
  const updatedValues = {
    ...currentValues,
    roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
  };

  formRef.current?.setFieldsValue(updatedValues);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  //

  const onSubmit = (value) => {
    formRef.current?.submit();
  };

  const onFinish = (value) => {
    const roleName =
      value.roleName?.selectedRoleType || initialValues?.roleName;

    const selectedPermission = permissions.find((item) => {
      return item._id == value.roleName;
    });

    value.roleName = {
      permissions: selectedPermission?.permissions,
      selectedRoleType: selectedPermission?.selectedRoleType,
    };

    value.name = users.Response1.name;

    const data = {
      orgId: userConfig.orgId,
      userid: value.userid,
      emailId: value.emailId,
      firstName: value.firstName,
      lastName: value.lastName,
      password: value.password,
      role: 'supervisor',
      supplierId: value.supplierId,
      roleName: {
        permissions: selectedPermission?.permissions,
        selectedRoleType: selectedPermission?.selectedRoleType,
      },
    };
    userCreate(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
    window.location.reload();
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  React.useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (item.selectedRole === 'Supervisor') {
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        //

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    if (userConfig?.location || userConfig?.locationId) {
      const locations = locationsData?.locations?.filter(
        (l) =>
          l.name === userConfig?.location || l.name === userConfig?.locationId
      );
      const agencies = users?.Response1?.filter(
        (a) =>
          a.location === userConfig?.location ||
          a.location === userConfig?.locationId
      );

      setFilteredLocations(locations);
      setFilteredAgencies(agencies);
    } else {
      setFilteredLocations(locationsData?.locations?.slice(1));
      setFilteredAgencies(users?.Response1);
    }
  }, [locationsData, users]);

  return (
    <Modal
      title={isEdit ? 'Edit' : 'Add Supervisor'}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? 'Save' : 'Add '}
      autoComplete='off'>
      <Form
        name='supervisor'
        ref={formRef}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: 'Enter First Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Enter Last Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item label='Role' name='role' hidden={true}>
          <Select disabled>
            <Option value='supervisor'>Supervisor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Role Name'
          name='roleName'
          initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Enter Role Name' }]}>
          <Select
            // disabled={isEdit ? true : false}
            style={{ fontWeight: 'bold', color: 'gray' }}>
            {permissions?.map((level, index) => (
              <Option key={index} value={level?._id}>
                {level?.selectedRoleType}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Contractor Agency'
          name='supplierId'
          rules={[
            {
              required: true,
              message: 'Please select a contractor agency',
            },
            {
              validator: validateContractorStatus,
            },
          ]}>
          {/* <Select>
            {filteredAgencies?.map((option) => (
              <Option
                key={option.id}
                value={option.id}
                disabled={isEdit ? true : false}>
                {option.name}
              </Option>
            ))}
          </Select> */}
          <Select>
            <Option value={userConfig.userid}>{userConfig.firstName}</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Location' name='location'>
          {/* <Select disabled={isEdit ? true : false}>
            {filteredLocation?.locations?.map((option, index) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select> */}
          <Select>
            <Option value={userConfig?.locationId}>
              {userConfig?.locationName}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='User Id'
          name='userid'
          rules={[{ required: true, message: 'Enter User Id' }]}
          getValueFromEvent={(e) => e.target.value.toUpperCase()}>
          <Input autoComplete='off' disabled={isEdit ? true : false} />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Enter Password' },
            {
              pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
              message:
                'Must contain minimum eight characters, atleast one number and one special character',
            },
          ]}>
          <Input.Password maxLength={20} autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: 'Enter Email' },
            { type: 'email', message: 'Enter valid Email' },
            {
              // validator: (_, value) => {
              //   const emailRegex =
              //     /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
              //   if (emailRegex.test(value)) {
              //     return orgEmailValidator(value, userConfig.orgId);
              //   }
              // },
            },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
