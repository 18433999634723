import React from 'react';
import { Col, Row, Form, Button, Typography, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import './summaryStyle.css';

const { Title } = Typography;

const Summarygeneration = ({
  initialValues,
  onNext,
  onPrev,
  jobDetails,
  wageDetails,
}) => {
  const navigate = useNavigate();
  const formRef = React.useRef();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    onNext(values);
    navigate('/home/supervisor/employee');
    // window.location.reload();
  };
  /* eslint-disable */
  const { user, userConfig } = useAuth();

  const [data, setData] = React.useState(initialValues);
  const [jobdata, setsetData] = React.useState(jobDetails);

  const [wagedata, setwageData] = React.useState(wageDetails);
  const date = dayjs(data.Dob);
  const formattedDate = date.format('DD-MM-YYYY');
  //-----------------------
  const date1 = dayjs(jobdata.dlExpiry);
  const dlExpiry = date1.format('DD-MM-YYYY');
  //-----------------------
  const date2 = dayjs(jobdata.doj);
  const doj = date2.format('DD-MM-YYYY');
  //-----------------------
  const date3 = dayjs(jobdata.effectiveDate);
  const effectiveDate = date3.format('DD-MM-YYYY');
  //-------------------------
  // const date4 = dayjs(jobdata.subSkillDate);
  // const subSkillDate = jobdata.subSkillDate.format("DD-MM-YYYY");

  return (
    <div>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Row class='row'>
          <Col span={12} class='column'>
            <Title level={5} style={{ color: 'red' }}>
              Personal Details
            </Title>
            <Row>
              <span>First Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.firstName}</span>
            </Row>
            <Row>
              <span>Middle Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.middleName}</span>
            </Row>
            <Row>
              <span>Last Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.lastName}</span>
            </Row>
            <Row>
              <span>Gender</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gender}</span>
            </Row>
            <Row>
              <span>Date Of Birth</span>
              <span class='span2'>:</span>
              <span class='span3'>{formattedDate}</span>
            </Row>
            <Row>
              <span>Age</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.age}</span>
            </Row>
            <Row>
              <span>Mobile Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.mobile}</span>
            </Row>
            <Row>
              <span>Aadhaar Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{`XXXX XXXX ${`${data.aadharNo}`.slice(
                -4
              )}`}</span>
            </Row>
            {/* <Row>
              <span>PAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pan}</span>
            </Row>
            <Row>
              <span>Ration Card Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.rationcardno}</span>
            </Row>  */}
            <Row>
              <span>Voter Card Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.votercardno}</span>
            </Row>
            <Row>
              <span>Handicapped</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.votercardno}</span>
            </Row>
            <Row>
              <span>Disability Type</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.disabilityType}</span>
            </Row>
            <Row>
              <span>Disability Percentage</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.disabilityPercentage}</span>
            </Row>
            <Row>
              <span>ID Mark</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.idmark}</span>
            </Row>
            <Row>
              <span>Father/Guardians Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.fatherName}</span>
            </Row>
            <Row>
              <span>Marital Status</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.martialStatus}</span>
            </Row>
            {/* <Row>
              <span>Spouse's Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.spousename}</span>
            </Row>
            <Row>
              <span>No.Of.Children</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.childrenNo}</span>
            </Row> */}
            <Row>
              <span>Emegency Contact Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emergencyContactName}</span>
            </Row>
            <Row>
              <span>Emegency Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emergencyContactNo}</span>
            </Row>
            <Row>
              <span>PF Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>PF Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo}</span>
            </Row>
            <Row>
              <span>ESIC Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>ESIC Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno}</span>
            </Row>
            <Row>
              <span>UAN Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>UAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan}</span>
            </Row>
            <Row>
              <span>Unit Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.unitName}</span>
            </Row>
            <Row>
              <span>Workmen Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.empCode}</span>
            </Row>
            <Row>
              <span>EPN Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.epnNumber}</span>
            </Row>
            <Row>
              <span>Location Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.locationId}</span>
            </Row>
            <Row>
              <span>Police Verification</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.policeVerification}</span>
            </Row>
            <Row>
              <span>Gate Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gateNumber}</span>
            </Row>
            {/* <Row>
              <span>Safety Training From Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.safetyTrainingFromDate
                  ? data.safetyTrainingFromDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Safety Training Upto Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.safetyTrainingUptoDate
                  ? data.safetyTrainingUptoDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Safety Training Remarks</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.safetyTrainingRemarks}</span>
            </Row> */}
            {/* <Row>
              <span>Status</span>
              <span class='span2'>:</span>
              <span class='span3'>{data?.status}</span>
            </Row> */}
            {/* <Row>
              <span>Hazardous Process Area</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.hazardousProcessArea}</span>
            </Row> */}
            <Row>
              <span>Technical Details</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.technicalDetails}</span>
            </Row>
            <Row>
              <span>Type Of Contract</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.natureOfWork}</span>
            </Row>
            {/* <Row>
              <span>Employement Status</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.statusToBeImposed ? data.statusToBeImposed : 'Active'}
              </span>
            </Row> */}
            <Title level={5}>Aadhaar Addess</Title>
            <Row>
              <Col>Permanent Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='address'>{data.address1}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 2</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address2}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 3</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.village}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.district}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.state}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pincode}</span>
            </Row>
            <Row>
              <span>Taluka</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.taluka}</span>
            </Row>
            <Row>
              <span>Tehsil</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.tehsil}</span>
            </Row>
            <Row>
              <span>City</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.city}</span>
            </Row>
            <Row>
              <span>Country</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.country}</span>
            </Row>
            <Title level={5}>Present Address</Title>
            <Row>
              <Col>Present Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress1}</span>
            </Row>
            <Row>
              <span>Present Address Line 2</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress2}</span>
            </Row>
            <Row>
              <span>Present Address Line 3</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pdistrict}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pstate}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppincode}</span>
            </Row>
            <Row>
              <span>Taluka</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ptaluka}</span>
            </Row>
            <Row>
              <span>Tehsil</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ptehsil}</span>
            </Row>
            <Row>
              <span>City</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pcity}</span>
            </Row>
            <Row>
              <span>Country</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pcountry}</span>
            </Row>
            <hr />
            {/* <Title level={5} style={{ color: 'red' }}>
              Wage Details
            </Title>
            <Row>
              <span>Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.basic}</span>
            </Row>
            <Row>
              <span>DA</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.da}</span>
            </Row>
            <Row>
              <span>Allowance-1</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.allowance1}</span>
            </Row>
            <Row>
              <span>Allowance-2</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.allowance2}</span>
            </Row>
            <Row>
              <span>HRA</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.hra}</span>
            </Row>
            <Row>
              <span>Conveyance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.conveyance}</span>
            </Row>
            <Row>
              <span>Special Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.specialAllowance}</span>
            </Row>
            <Row>
              <span>Medical Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.medicalAllowance}</span>
            </Row>
            <Row>
              <span>Education Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.eduAllowance}</span>
            </Row>{' '}
            <Row>
              <span>Leave/Travel Allowance</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.travelAllowance}</span>
            </Row>
            <Row>
              <span>Effective Date</span>
              <span class='span2'>:</span>
              <span class='span3'>{effectiveDate}</span>
            </Row> */}
          </Col>

          <Col span={12} class='column2' style={{ paddingLeft: '50px' }}>
            <Title level={5} style={{ color: 'red' }}>
              Job Details
            </Title>
            <Row>
              <span>Principal Employer Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{userConfig.orgId}</span>
            </Row>
            <Row>
              <span>Contracror Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{userConfig.supplierId}</span>
            </Row>
            <Row>
              <span>Department Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.departmentCode}</span>
            </Row>
            {/* <Row>
              <span>Section Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.sectionCode}</span>
            </Row> */}
            {/* <Row>
              <span>Work Order Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workorderCode}</span>
            </Row>{' '} */}
            {/* <Row>
              <span>Item Service Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.itemServiceCode}</span>
            </Row> */}
            <Row>
              <span>Worker Category</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.workerCategory}</span>
            </Row>
            {/* <Row>
              <span>Labour Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.labourType}</span>
            </Row> */}
            <Row>
              <span>Job Order Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.jobOrderNumber}</span>
            </Row>
            <Row>
              <span>Skill Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.skillCode}</span>
            </Row>{' '}
            {/* <Row>
              <span>Sub Skill Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.subSkillCode}</span>
            </Row>{' '} */}
            <Row>
              <span>Designation</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.designation}</span>
            </Row>{' '}
            {/* <Row>
              <span>Contractor Reference Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.contractorReferenceNumber}</span>
            </Row> */}
            {/* <Row>
              <span>SubSkill Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.subSkillDate
                  ? jobdata.subSkillDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row> */}
            <Row>
              <span>Date Of Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{doj}</span>
            </Row>
            {/* <Row>
              <span>Employment status</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.employmentStatus === undefined ? 'No' : 'Yes'}
              </span>
            </Row> */}
            {/* <Row>
              <span>Date of termination</span>
              <span class="span2">:</span>
              <span class="span3"></span>
            </Row> */}
            {/* <Row>
              <span>Reason for termination</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.terminationReason}</span>
            </Row> */}
            {/* <Title level={5}>Work Experience</Title>
            <Row>
              <span>Total experience</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.totalExperience}</span>
            </Row>
            <Row>
              <span>Previous Organization</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.previousOrg}</span>
            </Row>
            <Row>
              <span>Referred by</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.referredBy}</span>
            </Row>
            <Title level={5}>Relative working in company</Title>
            <Row>
              <span>Is Working?</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.isworking === undefined ? 'No' : 'Yes'}
              </span>
            </Row>
            <Row>
              <span>Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.name}</span>
            </Row>
            <Row>
              <span>Mobile Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.mobileNo}</span>
          </Row> */}
            <Title level={5}>Other Details</Title>
            {/* <Row>
              <span>Shoe Size</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shoeSize}</span>
            </Row> */}
            <Row>
              <span>Blood Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bloodGroup}</span>
            </Row>
            <Row>
              <span>Academic Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.academicQualification}</span>
            </Row>
            <Row>
              <span>Technical Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.techQualification}</span>
            </Row>
            <Row>
              <span>Driving Licence </span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.dl}</span>
            </Row>{' '}
            <Row>
              <span>Driving Licence Expiry Date</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dlExpiry ? jobdata.dlExpiry.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Title level={5}>Bank Details</Title>
            <Row>
              <span>Bank Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bankname}</span>
            </Row>
            <Row>
              <span>Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.AccountNo}</span>
            </Row>
            <Row>
              <span>IFSC Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.ifsc}</span>
            </Row>
            {/*
            <Row>
              <span>Name as per Bank Account</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.userName}</span>
            </Row> */}
          </Col>
        </Row>
        <Divider />
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            onClick={() => onPrev(formRef.current?.getFieldsValue())}
            type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Summarygeneration;

// const data =[{"name":"test1"},{"name":"test2"}];
// return (
//   <div>
//   {data.map(function(d, idx){
//      return (<li key={idx}>{d.name}</li>)
//    })}
//   </div>
// );
// }
