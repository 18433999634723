import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Form,
  Upload,
  Col,
  Typography,
  Modal,
  Select,
  Space,
  Input,
  Table,
  message,
  notification,
  Row,
  DatePicker,
} from 'antd';

import {
  UploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import axios from '../../services/axiosInstance';
import {
  deleteDocumentFromGcp,
  downloadDocument,
  getSignedUrl,
  uploadDocument,
  uploadFileSafety,
} from '../../services/auth';
import {
  docxIcon,
  imgIcon,
  pdfIcon,
  txtIcon,
  xlsxIcon,
} from '../../assets/images';
import useAuth from '../../hooks/useAuth';

import dayjs from 'dayjs';
import DocumentPreview from '../DocumentPreview';
const { Title } = Typography;
const { Option } = Select;

const getIcon = (record) => {
  if (!record?.type) return '';

  switch (true) {
    case record?.type?.startsWith('image/'):
      return imgIcon;
    case record?.type === 'application/pdf':
      return pdfIcon;
    case record?.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docxIcon;
    case record?.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsxIcon;
    case record?.type === 'text/plain':
      return txtIcon;
    default:
      return '';
  }
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const allowedFileSize = 500 * 1024; // 5 MB in bytes

const Uploaddocument = ({
  initialValues,
  onNext,
  onPrev,
  isViewOnly,
  personalDetails,
  jobDetails,
}) => {
  console.log('initialValues84', initialValues);
  initialValues?.annualMedicalCheckupDate &&
    (initialValues.annualMedicalCheckupDate = dayjs(
      new Date(initialValues?.annualMedicalCheckupDate)
    ));
  const [documentsList, setDocumentsList] = useState([]);
  const [preview, setPreview] = useState({});
  const formRef = React.useRef();
  const [messageApi, contextHolder] = message.useMessage();
  // const isDisabled = initialValues?.approvalStage > 0;
  const { user, userConfig } = useAuth();

  const isDisabled = userConfig.role === 'approver';

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = () => {
    if (validateDocuments()) {
      onNext({
        document: documents,
        // annualMedicalCheckupDate: new Date(
        //   annualMedicalCheckupDate
        // ).toISOString(),
        // remark: remarkSet,
      });
    }
    return 0;
  };
  useEffect(() => {
    getEmpList();
  }, []);

  const validateDocuments = () => {
    let error = [];
    documentsList.map((document) => {
      if (document.isRequired) {
        const exists = documents.some(
          (item) => item.name === document.documentName
        );
        if (!exists) {
          error.push(`${document.documentName} document is required`);
        }
      }
    });
    if (error.length) {
      error.map((err) => {
        notification.error({
          message: err,
        });
      });

      return 0;
    }
    return true;
  };

  const getEmpList = async () => {
    try {
      if (
        userConfig.role === 'supervisor' || //&&
        userConfig.role === 'approver'
        // personalDetails?.approvalStage <= 0
        //personalDetails?.status !== 'approved'
      ) {
        const response = await axios.get('/org/locations');

        response?.data?.org?.locations.forEach((value) => {
          if (value.id === personalDetails.locationId) {
            // setDocumentsList(value?.documents || []);
            //Sachin Code
            setDocumentsList(value?.documents?.centralIR || []);
          }
        });
      }
    } catch {}
  };
  const [data, setData] = useState(initialValues);
  const [annualMedicalCheckupDate, setAnnualMedicalCheckupDate] = useState(
    new Date(initialValues?.annualMedicalCheckupDate) || null
  );
  const [selectedOption, setSelectedOption] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [documents, setDocuments] = useState(initialValues?.document || []);
  console.log('documents168', documents);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [remarkSet, setremarkSet] = useState('');

  const handleRemarkChange = (value) => {
    setremarkSet(value.target.value);
  };
  // const [previewTitle, setPreviewTitle] = useState("");
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const type = info.file?.type;
      const fileName = `${jobDetails?.psNumber}_${
        userConfig?.orgId
      }_${Date.now()}_${info.file?.name}`;
      const filePath = `${process.env.REACT_APP_GCP_PATH}/${userConfig?.orgId}/regular_workmen/${fileName}`;

      const formData = new FormData();
      formData.append('file', info.file?.response);
      formData.append('fileName', filePath);

      uploadDocument(formData);

      let document = {
        name: selectedOption == 'others' ? remarkSet : selectedOption,
        fileName: info.file?.name,
        type,
        filePath,
      };

      let available = false;
      const updatedDocuments = documents?.map((item) => {
        if (item.name === document?.name) {
          //item.imageUrl = url;
          available = true;
        }
        return item;
      });

      if (available) {
        setDocuments([...updatedDocuments]);
        return;
      }
      setDocuments([...documents, document]);
    }
  };
  // const handleChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     // setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, (url) => {
  //       // setLoading(false);
  //       let document = {
  //         name: selectedOption == 'others' ? remarkSet : selectedOption,
  //         imageUrl: url,
  //         // remarks: remarkSet,
  //       };

  //       let available = false;
  //       const updatedDocuments = documents.map((item) => {
  //         if (item.name === document.name) {
  //           item.imageUrl = url;
  //           available = true;
  //         }
  //         return item;
  //       });

  //       if (available) {
  //         setDocuments([...updatedDocuments]);
  //         return;
  //       }
  //       setDocuments([...documents, document]);
  //     });
  //   }
  // };
  const handleCancel = () => setPreviewOpen(false);
  function handleSelect(value) {
    setSelectedOption(value);
    // const isShowUpload = value === "SSLC" || value === "PUC";
    // setShowUpload(isShowUpload);
  }
  const handleDelete = (name) => {
    const updatedData = documents.filter((item) => item.name !== name);
    setDocuments([...updatedData]);
    // setData(updatedData);
  };

  const downloadFile = async (record) => {
    try {
      const response = await downloadDocument(record?.filePath);
      const blob = new Blob([response?.data]);
      const url = window.URL.createObjectURL(blob);

      const a = document?.createElement('a');
      a.href = url;
      a.download = record?.fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const previewFile = async (record) => {
    const { data } = await getSignedUrl(encodeURIComponent(record?.filePath));
    setPreview({
      url: data?.data,
      type: record?.type,
      name: record?.name,
    });
    setPreviewOpen(true);
  };

  const deleteDocument = async (record) => {
    try {
      const { status } = await deleteDocumentFromGcp(
        encodeURIComponent(record?.filePath)
      );
      if (status == 200) handleDelete(record?.name);
    } catch (error) {
      if (error.message.includes(404)) handleDelete(record?.name);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      key: 'age',
      render: (text, record) => {
        const type = record.type;
        return <img src={getIcon(record)} height={'50px'} width={'50px'} />;
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            type='link'
            title='Preview'
            icon={<EyeOutlined />}
            onClick={() => previewFile(record)}></Button>
          <Button
            type='link'
            title='Download'
            icon={<DownloadOutlined />}
            onClick={() => downloadFile(record)}></Button>
          <Button
            type='link'
            title='Delete'
            icon={<DeleteOutlined style={{ color: 'red' }} />}
            onClick={() => deleteDocument(record)}
            disabled={isDisabled}></Button>
        </Space>
      ),
    },
  ];
  const handleCustomRequest = ({ file, onSuccess, onError }) => {
    onSuccess(file);
  };

  const handleBeforeUpload = (file) => {
    // Check if the file size is within the allowed limit
    if (file.size > allowedFileSize) {
      message.error('File size exceeds the limit (500 KB)');
      return false; // Prevent upload
    }

    return true;
  };

  return (
    <div className='upload-document'>
      <Form
        initialValues={initialValues}
        onFinish={onFinish}
        ref={formRef}
        layout='vertical'>
        <Title level={5}>Upload Document</Title>

        <Row>
          {/* <Col span={6}>
            <Form.Item
              name='annualMedicalCheckupDate'
              label='Next Annual Medical Checkup Date'
              rules={[
                {
                  required:
                    userConfig.role === 'supervisor' &&
                    personalDetails?.status !== 'approved',
                  message: 'Next Annual Medical Checkup Date is Mandatory',
                },
              ]}>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                disabled={isDisabled}
                value={annualMedicalCheckupDate}
                onChange={(e) => {
                  setAnnualMedicalCheckupDate(dayjs(e, 'YYYY-MM-DD'));
                }}
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} lg={24}>
            <Form.Item
              name='logoFile'
              label='Upload Document'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={[]}>
              <Select
                onChange={handleSelect}
                style={{ width: '400px' }}
                title={document.documentName}
                size='large'>
                <Option value='Select Document' disabled>
                  Select Document
                </Option>
                {documentsList?.map((document) => {
                  return (
                    <Option
                      key={document.documentName}
                      value={document.documentName}
                      title={document.documentName}>
                      {document.isRequired ? (
                        <span style={{ color: 'red' }}> *</span>
                      ) : (
                        ''
                      )}{' '}
                      {document.documentName}
                    </Option>
                  );
                })}
              </Select>

              {selectedOption && (
                <Upload
                  listType='picture'
                  accept='image/*'
                  showUploadList={false}
                  customRequest={handleCustomRequest}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleChange}
                  // className='custom-upload'
                  // fileList={[]}
                >
                  <Button
                    icon={<UploadOutlined />}
                    name='uploaddocument'
                    style={{
                      width: '150px',
                      fontWeight: 'bold',
                      marginLeft: '20px',
                    }}
                    size='large'
                    disabled={
                      !selectedOption ||
                      documents?.some((item) => item.name === selectedOption)
                    }>
                    Upload
                    {/* {selectedOption} */}
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col
            // className='gutter-row'
            // span={6}
            hidden={
              selectedOption !== ('others' || 'Others' || 'Other' || 'other')
            }>
            <Form.Item
              name='remark'
              label='Remark'
              // rules={[{ required: true, message: 'Enter EPN Number' }]}
            >
              <Input size='large' onChange={handleRemarkChange} />
            </Form.Item>
          </Col>
        </Row>
        <Col xs={24} lg={24}>
          {documents.length ? (
            <>
              <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                Selected Document
              </div>
              <Table dataSource={documents} columns={columns} />
            </>
          ) : (
            ''
          )}

          <Modal
            visible={previewOpen}
            title={preview?.name}
            footer={null}
            onCancel={handleCancel}>
            <DocumentPreview url={preview?.url} fileType={preview?.type} />
          </Modal>
        </Col>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            onClick={() => {
              onPrev({
                document: documents,
                annualMedicalCheckupDate: annualMedicalCheckupDate,
              });
            }}
            type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Uploaddocument;
